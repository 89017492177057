.header {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    background-image: url("../assets/header.jpg");
    /*height: 100vh;*/
    text-align: left;
}

.block {
    width: 70%;
    min-width: 320px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 96px 32px;
    margin: auto;
}

.block .half.info {
    min-width: 400px;
    max-width: 480px;
}

.block .half {
    padding: 92px 0 68px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.block.info .half {
    transform: translateX(-32px);
}

.block.info .half:first-child {
    background: linear-gradient(to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 0.8px),
    rgba(244, 176, 20, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 0.8px),
    rgba(0, 0, 0, 0) 100%);
}

.block.info .half:last-child {
    background: linear-gradient(to top left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    rgba(129, 81, 22, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 1px),
    rgba(0, 0, 0, 0) 100%);
}

.block.info .half:first-child .background {
    background-color: #f4b014;
}

.block.info .half:last-child .background {
    background-color: #815116;
}

.block .half .background img {
    margin-right: 16px;
    align-self: center;
}

.block .half .background {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 32px;
    transform: translateX(32px);
}

.block .half span {
    line-height: 1;
    color: #fff;
}

.block.info .half:first-child span:first-child {
    color: #815116;
}

.block.info .half:first-child span:first-child,
.block.info2 .half:first-child span:first-child,
.block.info3 .half:first-child span:first-child {
    color: #f4b014;
}

.block .half p * {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    font-weight: bold;
}

.block.info {
    justify-content: space-around;
}

.description p {
    margin: 0 0 16px 0;
}

.description a {
    display: inline-flex;
    align-items: center;
    background-color: #fff;
    padding: 8px 26px;
    text-decoration: none;
    color: #7f674c;
    font-size: 18px;
    font-weight: bold;
}

.block.info2 .half:last-child,
.block.info4 .half:last-child {
    padding: 0;
    width: 50%;
}

.block.info2,
.block.info4 {
    justify-content: space-around;
}

.block.info2 .flex,
.block.info4 .flex,
.block.info5 .flex {
    display: flex;
    width: 100%;
    align-items: center;
}

.block.info2 .flex img,
.block.info4 .flex img,
.block.info5 .flex img {
    margin-right: 16px;
}

.block.info2 .half:first-child,
.block.info4 .half:first-child {
    max-width: 440px;
}


.btn {
    padding: 8px 20px;
    border-radius: 0;
    overflow: hidden;
}

.btn::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            120deg,
            transparent,
            blue,
            transparent
    );
    transform: translateX(-100%);
    transition: 0.6s;
}

.btn:hover {
    background: transparent;
    box-shadow: 0 0 20px 10px hsla(204, 70%, 53%, 0.5);
}

.btn:hover::before {
    transform: translateX(100%);
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    color: white;
    background: #fff;
    box-shadow: 0 0.4px 0.4px rgba(128, 128, 128, 0.109),
    0 1px 1px rgba(128, 128, 128, 0.155),
    0 2.1px 2.1px rgba(128, 128, 128, 0.195),
    0 4.4px 4.4px rgba(128, 128, 128, 0.241),
    0 12px 12px rgba(128, 128, 128, 0.35);
    padding: 32px;
}

.login-form .form-input-material {
    margin: 8px 5% 8px 0;
    display: inline-block;
    width: 40%;
}

.login-form .btn {
    width: 100%;
    margin: 18px 0 9px 0;
}

.login-form > div > div.form-input-material:nth-of-type(1),
.login-form > div > div.form-input-material:nth-of-type(2) {
    width: 50%;
}

.login-form .form-input-material.row {
    width: 85%;
}

.block.info2 .login-form > .widget,
.block.info4 .login-form > .widget {
    position: relative;
    display: flex;
}

.block.info2 .login-form > .widget .widget-content,
.block.info4 .login-form > .widget .widget-content {
    flex: 1;
    color: #6d6357;
    padding-left: 24px;
}

.block.info2 .login-form > .widget .widget-header,
.block.info4 .login-form > .widget .widget-header {
    border-right: 2px solid #f4b014;
    text-align: center;
    color: #fff;
    width: 28px;
}

.block.info2 .login-form h1 p,
.block.info4 .login-form h1 p {
    margin: 0;
}

.block.info2 .login-form h1 p:last-child,
.block.info4 .login-form h1 p:last-child {
    color: #815116;
}

.block.info2 .login-form h1,
.block.info4 .login-form h1 {
    color: #f4b014;
    margin: 0;
    display: flex;
}

.block.info2 .login-form > .widget .widget-header .header-content,
.block.info4 .login-form > .widget .widget-header .header-content {
    transform: rotate(-90deg);
    transform-origin: 0 100%;
    position: absolute;
    left: 20px;
    bottom: 0;
    margin: 0;
    font-size: 12px;
    color: #f4b014;
    font-weight: bold;
}

input:-webkit-autofill {
    color: #2a2a2a !important;
}

.block.info2 .container,
.block.info3 .container,
.block.info4 .container,
.block.info5 .container {
    width: 50%;
}

.block.info3 .container {
    padding: 48px;
}

.login-form .submit-button {
    color: #543510;
}

.login-form .form-header img {
    margin-right: 16px;
    height: max-content;
}

.login-form .form-header {
    margin-bottom: 24px;
    justify-content: left;
    display: inline-flex;
    align-items: center;
}

.login-form .widget {
    margin-bottom: 32px;
}

.block.info2 .login-form > .widget.last p,
.block.info4 .login-form > .widget.last p {
    font-size: 12px;
}

.block.info2 .login-form > .widget.last .widget-content > div,
.block.info4 .login-form > .widget.last .widget-content > div {
    display: inline-block;
}

.block.info2 .login-form > .widget.last .widget-content,
.block.info4 .login-form > .widget.last .widget-content {
    display: inline-flex;
}

.block.info2 .login-form > .widget.last .widget-header,
.block.info4 .login-form > .widget.last .widget-header {
    border: 0;
}

.login-form .widget.last {
    margin: 0;
}

.header .contacts a {
    background-color: #f4b014;
    padding: 14px 26px;
    text-decoration: none;
    color: #7f674c;
    font-size: 18px;
    font-weight: bold;
}

.header .contacts span {
    margin: 0 30px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
}

.submit-button {
    padding: 8px 48px;
    font-size: 14px;
    border-radius: 8px;
    letter-spacing: 0;
    color: #543510;
    background-color: #f4b014;
    font-weight: bold;
    border: 0;
}

.block.info2 .container .description p,
.block.info4 .container .description p,
.block.info5 .container .description p {
    display: flex;
    flex-direction: column;
}

.block.info2 .container .description p,
.block.info3 .container .description p,
.block.info4 .container .description p,
.block.info5 .container .description p {
    font-size: 28px;
    color: #fff;
    font-weight: bold;
}

.block.info2 .container .description p span:first-child,
.block.info3 .container .description p span:first-child,
.block.info4 .container .description p span:first-child,
.block.info5 .container .description p span:first-child {
    color: #f4b014;
}

.block.info2 .container.left > *,
.block.info4 .container.left > *,
.block.info5 .container.left > * {
    padding: 0 64px;
    width: auto;
}

.block.info2 .container.left > p,
.block.info4 .container.left > p {
    font-size: 20px;
    color: #fff;
}

.block.info5 .container.left p {
    color: #fff;
}

.block.info5 .container.left p b {
    color: #f4b014;
}

.block.info5 .supported-model {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 25%;
    font-size: 22px;
}

.block.info5 .supported-model label:first-child {
    color: #f4b014;
    font-weight: bold;
}

.block.info5 .supported-model label:last-child {
    color: #fff;
}

footer {
    background-color: #fff;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

footer .header-row {
    display: inline-flex;
    width: 80%;
    padding: 32px 0;
}

footer .header-row > div:first-child {
    width: 20%;
    align-self: center;
}

footer .header-row > div:last-child {
    width: 80%;
}

footer .footer-info {
    display: inline-flex;
    align-items: flex-end;
}

footer .footer-info > div {
    width: calc(100% / 3);
}

footer .footer-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .footer-info .footer-heading img {
    margin-right: 12px;
}

footer .footer-info .footer-heading {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

footer .footer-info .footer-heading h3 {
    margin: 0;
    color: #815116;
}

footer .footer-info p {
    margin: 0;
}

@media only screen and (max-width: 1200px) {
    .block {
        flex-direction: column;
    }

    .block.info2 .container,
    .block.info4 .container {
        width: 80%;
    }

    .block.info2 .container, .block.info3 .container, .block.info4 .container, .block.info5 .container {
        width: 100%;
    }

    footer .header-row {
        width: 100%;
    }
}


@media only screen and (min-width: 1201px) {
    footer .header-row {
        width: 1100px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: 1rem;
}

@media only screen and (min-width: 640px) {
    .contacts {
        display: block;
    }
}
